import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgxBarcodeModule } from 'ngx-barcode';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { Downloader } from '@ionic-native/downloader/ngx';
import { Network } from '@ionic-native/network/ngx';
import { LocationAccuracy } from '@ionic-native/location-accuracy/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { FormBuilder } from '@angular/forms';
import { DatePipe, registerLocaleData } from '@angular/common';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CookieService } from 'ngx-cookie-service';
import localeAr from '@angular/common/locales/ar';
import localeEn from '@angular/common/locales/en';
import { WebheaderModule } from "./webheader/webheader.module";
import { RecaptchaModule, RecaptchaFormsModule ,RECAPTCHA_LANGUAGE } from "ng-recaptcha";
import { RecaptchaProvider } from './locale.provider';
import { GeneralmethodsService } from './_services/generalmethods.service';

registerLocaleData(localeAr);
registerLocaleData(localeEn);
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
  }

@NgModule({
    declarations: [
        AppComponent,
    ],
    entryComponents: [],
    providers: [
        // RecaptchaProvider,
        // {
        //     provide: RECAPTCHA_LANGUAGE,
        //     useFactory: (toString: string) => toString,
        //     deps: [LocaleProvider],
        //   },
        {
            provide: RECAPTCHA_LANGUAGE,
            useFactory: (locale: string) => locale,
            deps: [LOCALE_ID],
          },
        {
            provide: LOCALE_ID,
            useFactory: (localeService: GeneralmethodsService) => {
              //console.log('locale ID', localeService.getLocale());
              return localeService.getLocale();
            },
            deps: [GeneralmethodsService]
          },
        CookieService,
        Diagnostic,
        DatePipe,
        FormBuilder,
        InAppBrowser,
        Document,
        LocationAccuracy,
        Geolocation,
        Network,
        Storage,
        Downloader,
        ScreenOrientation,
        StatusBar,
        AppVersion,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        BarcodeScanner,
    ],
    bootstrap: [AppComponent],
    imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule, NgxBarcodeModule, HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }), WebheaderModule, RecaptchaModule]
})
export class AppModule {

  constructor(){}
  
}
